import React, { useState } from 'react';
import { Spin as Hamburger } from 'hamburger-react';
import { Drawer } from 'antd';

import Flex from 'components/ui/atoms/flex/Flex';
import UserSection from 'components/ui/organisms/sidebar/ui/userSection/UserSection';
import SidebarView from 'components/ui/organisms/sidebar/SidebarView';

import { colors } from 'theme/colors';

import { WIDTH_SIDEBAR } from 'utils/layout/constants';

import classes from './TopBarView.module.scss';

const TopBarView: React.FC = () => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        className={classes.top_bar_view}
      >
        <Hamburger
          color={colors.COLOR_PRIMARY}
          toggled={isDrawerOpened}
          onToggle={(toggled) => setIsDrawerOpened(toggled)}
        />
        <UserSection collapsible={false} className={classes.user_section} />
      </Flex>
      <Drawer
        open={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
        placement="left"
        headerStyle={{ display: 'none' }}
        bodyStyle={{ padding: '0' }}
        width={WIDTH_SIDEBAR}
      >
        <SidebarView onCollapseButtonClick={() => setIsDrawerOpened(false)} insideDrawer />
      </Drawer>
    </>
  );
};

export default TopBarView;
