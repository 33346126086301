import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { route } from 'application/routes/route';

import Fade from 'components/animations/fade/Fade';
import LinkRouter from 'components/ui/atoms/link/LinkRouter';
import Paragraph from 'components/ui/atoms/typography/Paragraph';
import { useSidebarViewModel } from 'components/ui/organisms/sidebar/viewModel/context';
import Flex from 'components/ui/atoms/flex/Flex';
import AvatarEmpty from 'components/ui/atoms/icons/avatar/AvatarEmpty';

import { tokenRepository } from 'domain/repositories/other/TokenRepository';
import { authRepository } from 'domain/repositories/api';
import { LogoutUseCase } from 'domain/useCases/auth/LogoutUseCase';

import { useStore } from 'storesMobx/MobxStoreProvider';

import { ns } from 'utils/locales';

import classes from './UserSection.module.scss';

type TProps = {
  collapsible?: boolean;
  className?: string;
}

function UserSection(props: TProps) {
  const { collapsible = true, className } = props;

  const { isSidebarCollapsed } = useSidebarViewModel();
  const { t } = useTranslation([ns.common]);

  const { userStore } = useStore();

  const logoutUseCase = useMemo(
    () => new LogoutUseCase({ requestCallback: authRepository.logout, tokenRepository, userStore }),
    [],
  );

  const handleLogout = async () => {
    await logoutUseCase.fetch({ payload: undefined });
  };

  return (
    <Flex
      align="center"
      gap={8}
      className={clsx(classes.user_section, {
        [classes.user_section_is_sidebar_collapsed]: isSidebarCollapsed && collapsible,
      }, className)}
    >
      <AvatarEmpty />
      <Fade isVisible={!isSidebarCollapsed || !collapsible}>
        <Flex vertical gap={8}>
          <LinkRouter to={route.user(userStore.profile?.id)} className={classes.link}>
            <Paragraph>{userStore.profile?.name}</Paragraph>
          </LinkRouter>
          <Flex gap={45}>
            <LinkRouter to={route.settings()} className={classes.link}>
              <Paragraph>{t(`${ns.common}:userSection.settings`)}</Paragraph>
            </LinkRouter>
            <button type="button" className={clsx(classes.link, classes.link_danger)}>
              <Paragraph onClick={handleLogout}>{t(`${ns.common}:userSection.logout`)}</Paragraph>
            </button>
          </Flex>
        </Flex>
      </Fade>
    </Flex>
  );
}

export default observer(UserSection);
